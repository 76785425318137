import { Grid } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { Toast } from 'react-bootstrap';
import { RiUploadCloud2Line } from "react-icons/ri";
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { FaFilePdf, FaImage } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";

const ReimbursementUpload = ({
    files,
    setFiles
}) => {
    const fileInputRef = useRef(null);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [uploadFileNames, setUploadFileNames] = useState("");
    const [fileNameModal, setFileNameModal] = useState("");
    const [fileSizes, setFileSizes] = useState("");
    const [fileTypes, setFileTypes] = useState("");

    const handleDrop = (e) => {
        // e.preventDefault(); // Prevent default browser behavior
        let files = e;

        // Allowed file types
        const validTypes = ["image/jpeg", "image/png", "application/pdf"];
        const maxFileSize = 50 * 1024 * 1024; // 50MB

        // Filter valid files (correct type & within size limit)
        const validFiles = files.filter(file => validTypes.includes(file.type) && file.size <= maxFileSize);

        if (validFiles.length > 0) {
            setUploadFileNames(validFiles.map(file => file.name));
            setFileSizes(validFiles.map(file => formatFileSize(file.size)));
            setFileTypes(validFiles.map(file => file.type));
            setFileNameModal(true);
            setFiles(validFiles);
        } else {
            alert("Please upload JPEG, PNG, or PDF files within 50MB size limit.");
        }
    };

    const handleChangeFile = (e) => {
        const files = Array.from(e.target.files);

        if (files.length > 0) {
            // Allowed file types
            const validTypes = ["image/jpeg", "image/png", "application/pdf"];
            const maxFileSize = 50 * 1024 * 1024; // 50MB

            // Filter valid files (check type & size)
            const validFiles = files.filter(file => {
                console.log(`File: ${file.name}, Type: ${file.type}, Size: ${file.size} bytes`); // Debug log
                return validTypes.includes(file.type) && file.size <= maxFileSize;
            });

            if (validFiles.length > 0) {
                setUploadFileNames(validFiles.map(file => file.name));
                setFileSizes(validFiles.map(file => formatFileSize(file.size)));
                setFileTypes(validFiles.map(file => file.type));
            } else {
                setUploadFileNames([]);
                setFileSizes([]);
                setFileTypes([]);
                alert("Please upload JPEG, PNG, or PDF files within the 50MB size limit.");
            }
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Triggers the file input
    };

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} B`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    const handleDeleteFile = (index) => {
        setUploadFileNames(prev => prev.filter((_, i) => i !== index));
        setFileSizes(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <Grid container
            direction="row">
            <Grid item sm={8} md={8} style={{ padding: 15 }}>
                <h2>REIMBURSEMENT REQUEST</h2>
                <Dropzone onDrop={(e) => handleDrop(e)}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <Grid className="uploadWrapContainer" style={{ border: "1px solid #d0cece", borderStyle: "dashed", borderRadius: 30, padding: 15 }}>
                                <div className="drop-it-hot" id="drop-area">
                                    <form className="choose-files">
                                        <Grid className="button-wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", height: 316 }}>
                                            <RiUploadCloud2Line size={40} color='#fec465' style={{ margin: 40 }} />
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <label className="label" for="fileElem">Choose multiple files or drag & drop them here</label>
                                                <label style={{ color: "#babcc3" }} className="label" for="fileElem">JPEG, PNG, and PDF formats, up to 50MB</label>
                                            </div>
                                            <Grid style={{ position: 'relative', height: '40px', marginBottom: '15px' }}>
                                                <input
                                                    type="file"
                                                    id="fileElem"
                                                    accept=".png,.jpeg,.pdf"
                                                    onChange={handleChangeFile}
                                                    onClick={(e) => (e.target.value = null)}
                                                    style={{ display: "none" }} // Hide the default input
                                                    ref={fileInputRef}
                                                />
                                                <Button variant='outlined' onClick={handleButtonClick} style={{ padding: "10px 20px", cursor: "pointer", borderRadius: "25px", borderColor: "#60bec9", color: "#60bec9" }}>
                                                    BROWSE FILE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Grid>
                        </div>
                    )}
                </Dropzone>
            </Grid>
            <Grid item sm={4} md={4} style={{ borderLeft: "1px solid #dfdede", padding: 15 }}>
                {uploadFileNames.length > 0 ? (
                    uploadFileNames.map((name, index) => (
                        <div style={{ justifyContent: "space-between", display: "flex", alignItems: "flex-start", backgroundColor: "#e5e5e5", padding: 10, borderRadius: 10, margin: "15px 0px" }} key={index}>
                            <div style={{ padding: "10px 5px" }}>
                                {
                                    fileTypes[index]?.includes("image") ?
                                    <FaImage size={30} color='#1d8ed8' />
                                    :
                                    <FaFilePdf size={30} color='#d81d40' />
                                }
                            </div>
                            <div style={{ width: "100%", margin: "6px 15px" }}>
                                <div style={{ fontSize: 13, fontWeight: 400 }}>
                                    {name}
                                </div>
                                <div style={{ color: "#babdc3", fontSize: 12, display: "flex" }}>
                                    {fileSizes[index]}
                                    <div style={{ marginLeft: 23 }}>
                                        <IoIosCheckmarkCircle size={20} color='#3dbe8e' />
                                        Completed
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => handleDeleteFile(index)} style={{ cursor: "pointer" }}>
                                <RiDeleteBin6Line size={20} />
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No files selected</div>
                )}
            </Grid>
        </Grid>
    )
}

export default ReimbursementUpload
