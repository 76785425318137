import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { logoutApplication } from '../../components/layout/Header'
import { getOs } from '../../utils/utility'
import { getCardDetails, gethealthcard, getMyneeds, getProgramInfo, getproviderLink, getRxSimpleShareData } from '../ApiCall'
import './LeftSidebar.css'
export default class LeftSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: localStorage.getItem('subscriberName'),
      x: 'select',
      link: 'https://members.myflexcare.com/login/member',
      showmyneeds: false,
      dashboardCards: [],
      fliptLink: '',
      reviveLink:"",
      pharmaShow: false,
      isRevive:false
    }
  }

  componentDidMount() {
    var os = getOs()
    gethealthcard().then(resp => {
      let data = {
        memberSSN: resp.data.memberIdCardList[0].memberSSN,
        type: 'family'
      }
      getRxSimpleShareData(data).then(res => {
        res.map(value => {
          if (value.addon.includes('RxSimpleShare') && value.status === 'AC') this.setState({ pharmaShow: true }, ()=>{
            localStorage.setItem("pharmaShow", true)
          })
          if (value.addon.includes('UHSRx') && value.status === 'AC') this.setState({ isRevive : true }, ()=>{
            localStorage.setItem("isRevive", true)
          })
          if (value.addon.includes('Health Tools') && value.status === 'AC') this.setState({ isTelemed: true }, ()=>{
            localStorage.setItem("isTelemed", true)
          })
          if (value.addon.includes('UHF Homebase') && value.status === 'AC') this.setState({ isHomebase: true }, ()=>{
            localStorage.setItem("isHomebase", true)
          })
        })
      })
      getProgramInfo()
      .then(res => {
        if (res.data && res.data.programInfo) {
          var str = res.data.programInfo.programName
          if (str.includes('+')) {
            var str1 = res.data.programInfo.programName
            var res1 = str1.split(' ')
            this.setState({ programname: res1[0], programname2: ' with ' + res1[2], showwithacsm: true })
          } else {
            this.setState({ programname: res.data.programInfo.programName, showwithacsm: false })
          }
        } else {
          this.setState({ loader: false })
        }
      })
      .catch()
    })

    if (os == 'Mac OS') {
      this.setState({ link: 'https://members.myflexcare.com/login/member',
         fliptLink: 'https://apps.apple.com/us/app/southern-scripts/id938009409',
         reviveLink:'https://apps.apple.com/us/app/revivehealth-mobile/id6450846042' })
    }
    if (os == 'iOS') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://apps.apple.com/us/app/southern-scripts/id938009409',
        reviveLink:'https://apps.apple.com/us/app/revivehealth-mobile/id6450846042'
      })
    }
    if (os == 'iPadOS') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://apps.apple.com/us/app/southern-scripts/id938009409',
        reviveLink:'https://apps.apple.com/us/app/revivehealth-mobile/id6450846042'
      })
    }
    if (os == 'Windows') {
      this.setState({ link: 'https://members.myflexcare.com/login/member', 
        fliptLink: 'https://member.liviniti.com/home', 
        reviveLink:'https://member.myrevive.health/'})
    }
    if (os == 'Android') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://play.google.com/store/apps/details?id=org.yappymobile.southernscripts&pli=1',
        reviveLink:'https://play.google.com/store/apps/details?id=health.revive.mobile&hl=en_US&pli=1'
      })
    }
    if (os == 'Linux') {
      this.setState({ link: 'https://members.myflexcare.com/login/member', 
        fliptLink: 'https://member.liviniti.com/home',
        reviveLink:'https://member.myrevive.health/' })
    }

    getCardDetails().then(res => {
      if (res.data.response) {
        let modifiedData=res.data.response;
        var indexFindProvider = modifiedData.findIndex(item => item.cardtitle === "FindProvider");
        var indexCuramLifeCard = modifiedData.findIndex(item => item.cardtitle === "CuramLifeCard");
        if(localStorage.getItem("planId")=="10051" && localStorage.getItem("planId")=="10052" && localStorage.getItem("planId")=="10053" && localStorage.getItem("planId")=="10061" && localStorage.getItem("planId")=="10062" && localStorage.getItem("planId")=="10063"){
          // modifiedData.splice(indexFindProvider,1);
          modifiedData.splice(indexCuramLifeCard,1);
        }

        this.setState({
          dashboardCards: modifiedData,
        })
      }
    })
  }

  handleSignout = () => {
    if (sessionStorage.getItem('USER_FROM') == 'native' || sessionStorage.getItem('USER_FROM') == 'Native') {
      this.props.logoutprops()
    } else {
      logoutApplication()
    }
  }

  openprovider() {
    getproviderLink().then(res => {
      if (res.data && res.data.length > 0) {
        let providerLink = res.data[0].fieldValue
        let url = ''
        url = providerLink
        window.location.href = providerLink
      }
    })
  }

  opentelemed() {
    window.open(this.state.link)
  }
  findprovider(enable) {
    if (enable) {
      this.props.findprovider(false)
    } else {
      this.props.tempModalOpen()
    }
  }
  notices(enable) {
    if (enable) {
      this.props.notices(false)
    } else {
      this.props.tempModalOpen()
    }
  }
  faqopen(enable) {
    if (enable) {
      this.props.faqopen(false)
    } else {
      this.props.tempModalOpen()
    }
  }
  programinformation() {
    this.props.programinformation(false)
  }
  openCuramLink=() => {
    var os = getOs()
    if (os == 'Mac OS'||os == 'Windows'||os == 'Linux')
    window.open("https://www.universalhealthfellowship.org/curamlife-care-coordination-app/")
    else if (os == 'iOS')
    window.open("https://apps.apple.com/us/app/curamlife/id1609101662")
    else if(os == 'Android')
    window.open("https://play.google.com/store/apps/details?id=com.curamlife")
  }
  myneedsdata() {
    getMyneeds().then(res => {
      if (res.data.length > 0) {
        this.setState({ showmyneeds: true })
      } else {
        this.setState({ showmyneeds: false })
      }
    })
  }
  openFlipt = () => {
    window.open(this.state.fliptLink)
  }
  openRevie = () => {
    window.open(this.state.reviveLink)
  }
  render() {
    return (
      <div class="mob_leftdrawer">
        <img src={require('../Images/Banner/uhs-logo.svg')} class="mob_ld_user_div_logo_img" />

        <div class="mob_ld_user_div">
          <AccountCircleIcon />
          <div class="mob_ld_username">Hello, {this.state.username}</div>
        </div>

        <div class={`mob_ld_menu_container ${this.props.name == 'Dashboard' && 'mob_ld_menu_container_selected'}`}>
          <img
            src={require('../Images/LeftDrawer Icon/dashboard-icon-active (1).svg')}
            className="mob_ld_menu_img"
          />
          <div className="mob_ld_menu_name">Dashboard</div>
        </div>

        <Link to="/MobileNotification" class="mob_ld_menu_container">
          <img
            className="mob_ld_menu_img"
            src={require('../Images/notification/my_notifications_icon_active.svg')}
          />
          <div className="mob_ld_menu_name">My Notifications</div>
        </Link>

        <Link to="/AnnouncementMobile" class="mob_ld_menu_container">
          <img
            className="mob_ld_menu_img"
            src={require('../Images/LeftDrawer Icon/notices-icon-active.svg')}
          />
          <div className="mob_ld_menu_name">Announcements & Notices</div>
        </Link>

        <Link to="/EducationMobile" class="mob_ld_menu_container">
          <img
            className="mob_ld_menu_img"
            src={require('../Images/LeftDrawer Icon/member_education_icon.svg')}
          />
          <div className="mob_ld_menu_name">Member Education Panel</div>
        </Link>

        {this.state.dashboardCards &&
          this.state.dashboardCards.map(card => (
            <>
              {(() => {
                switch (card.cardtitle) {
                  case 'Documents':
                    return (
                      <>
                        <Link to="/DocumentsScreen" class="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/documents-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name">Documents</div>
                        </Link>
                        {(( localStorage.getItem("planId")!=="10051" && localStorage.getItem("planId")!=="10052" && localStorage.getItem("planId")!=="10053"
                        && localStorage.getItem("planId")!=="10061" && localStorage.getItem("planId")!=="10062" && localStorage.getItem("planId")!=="10063"
                        ) || (this.state.programname?.includes("ES") && this.state.isTelemed)) &&<div className="mob_ld_menu_container" onClick={() => this.openCuramLink()}>
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/CuramLife.svg')}
                          />
                          <div className="mob_ld_menu_name">CuramLife Care Coordination App</div>
                        </div>}
                        {this.state.isHomebase &&  
                        <div className="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/CuramLife_Homebase_Icon.png')}
                          />
                          <div className="mob_ld_menu_name">CuramLife Homebase App<br/>
                          <small style={{fontSize:'10px',color: '#555'}}>Medical Records powered by Docsnap</small>
                          </div>
                        </div>}
                        {((this.state.isTelemed && this.state.programname?.includes("ES") || !this.state.programname?.includes("ES"))) &&  <div className="mob_ld_menu_container" onClick={() => this.opentelemed()}>
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/telemed-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name">Telemedicine</div>
                        </div>}
                        {this.state.pharmaShow && localStorage.getItem('CLIENT_ID') != '5742' && localStorage.getItem('CLIENT_ID') != '4599' && (
                          <div className="mob_ld_menu_container" onClick={() => this.openFlipt()}>
                            <img
                              className="mob_ld_menu_img"
                              src={require('../../assets/images/pharma_benefits_icon_active.svg')}
                              style={{ width: '20px' }}
                            />
                            <div className="mob_ld_menu_name">RxSimpleShare</div>
                          </div>
                        )}
                        {this.state.isRevive && localStorage.getItem('CLIENT_ID') != '5742' && localStorage.getItem('CLIENT_ID') != '4599' && (
                          <div className="mob_ld_menu_container" onClick={() => this.openRevie()}>
                            <img
                              className="mob_ld_menu_img"
                              src={require('../../assets/images/pharma_benefits_icon_active.svg')}
                            />
                            <div className="mob_ld_menu_name">UHSRx</div>
                          </div>
                        )}
                      </>
                    )

                  case 'FindProvider':
                    return (
                      // (localStorage.getItem("planId")=="10051" && localStorage.getItem("planId")=="10052" && localStorage.getItem("planId")=="10053"
                      // && localStorage.getItem("planId")!=="10061" && localStorage.getItem("planId")!=="10062" && localStorage.getItem("planId")!=="10063"
                      // )&&
                      <div className="mob_ld_menu_container" onClick={() => this.findprovider(card.enable)}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/find-a-provider-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">Find a Provider</div>
                      </div>
                    )

                  case 'MembershipId':
                    return card.enable ? (
                      localStorage.getItem('CLIENT_ID') == '6548' ||
                      localStorage.getItem('CLIENT_ID') == 6548 ||
                      localStorage.getItem('CLIENT_ID') == '4367' ||
                      localStorage.getItem('CLIENT_ID') == 4367 ||
                      localStorage.getItem('CLIENT_ID') == '5540' ||
                      localStorage.getItem('CLIENT_ID') == 5540 ||
                      localStorage.getItem('CLIENT_ID') == '4376' ||
                      localStorage.getItem('CLIENT_ID') == 4376 ? (
                        <Link to="/HealthyShareCard" style={{ textDecoration: 'none' }} class="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name"> Membership Card</div>
                        </Link>
                      ) : (
                        <Link to="/DigitalHealthCardNew" style={{ textDecoration: 'none' }} class="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name">UHS Membership Card</div>
                        </Link>
                      )
                    ) : (
                      <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">UHS Membership Card</div>
                      </div>
                    )
                
                  case 'HealthyLife':
                    return card.enable ? (
                      <Link to="/HealthyShareCard" style={{ textDecoration: 'none' }} class="mob_ld_menu_container">
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">UHS Membership Card</div>
                      </Link>
                    ) : (
                      <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/membership-card-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">UHS Membership Card</div>
                      </div>
                    )
                  case 'ProgramInformation':
                    return card.enable ? (
                      <>
                        <Link to="/ProgramInformation" class="mob_ld_menu_container" style={{ textDecoration: 'none' }}>
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/program-info-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name">Program Information</div>
                        </Link>
                        {( localStorage.getItem("planId")!=="10051" && localStorage.getItem("planId")!=="10052" && localStorage.getItem("planId")!=="10053"&& localStorage.getItem("planId")!=="10061" && localStorage.getItem("planId")!=="10062" && localStorage.getItem("planId")!=="10063") 
                        && <Link to="/MobileCuramLifeCardNotification" class="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/CuramLife.svg')}
                          />
                          <div className="mob_ld_menu_name">
                            UHF / Curam Wellness Center™
                          </div>
                        </Link>}
                      </>
                    ) : (
                      <>
                        <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/LeftDrawer Icon/program-info-icon-active.svg')}
                          />
                          <div className="mob_ld_menu_name">Program Information</div>
                        </div>{' '}
                        <Link to="/MobileCuramLifeCardNotification" class="mob_ld_menu_container">
                          <img
                            className="mob_ld_menu_img"
                            src={require('../Images/CuramLife.svg')}
                            style={{ width: 30, height: 30, marginLeft: -5 }}
                          />
                          <div className="mob_ld_menu_name">UHF / Curam Wellness Center™</div>
                        </Link>
                      </>
                    )

                  case 'MyNeeds':
                    return card.enable ? (
                      <Link to="/MyNeedsMobile" class="mob_ld_menu_container" style={{ textDecoration: 'none' }}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/my-needs-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">My Needs</div>
                      </Link>
                    ) : (
                      <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/my-needs-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">My Needs</div>
                      </div>
                    )

                  case 'MyTransaction':
                    return card.enable ? (
                      <Link to="/MyTransactionMobile" class="mob_ld_menu_container" style={{ textDecoration: 'none' }}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/my_transactions_icon_active.svg')}
                        />
                        <div className="mob_ld_menu_name">My Transactions</div>
                      </Link>
                    ) : (
                      <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/my_transactions_icon_active.svg')}
                        />
                        <div className="mob_ld_menu_name">My Transactions</div>
                      </div>
                    )

                  case 'HealthQuestionnaire':
                    return card.enable ? (
                      <Link to="/MobileMedical" style={{ textDecoration: 'none' }} class="mob_ld_menu_container">
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/medical-q-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">Health Questionnaire</div>
                      </Link>
                    ) : (
                      <div className="mob_ld_menu_container" onClick={() => this.props.tempModalOpen()}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/medical-q-icon-active.svg')}
                        />
                        <div className="mob_ld_menu_name">Health Questionnaire</div>
                      </div>
                    )
                  case 'FAQs':
                    return (
                      <div className="mob_ld_menu_container" onClick={() => this.faqopen(card.enable)}>
                        <img
                          className="mob_ld_menu_img"
                          src={require('../Images/LeftDrawer Icon/FAQs icon (active).svg')}
                        />
                        <div className="mob_ld_menu_name">FAQs</div>
                      </div>
                    )
                }
              })()}
            </>
          ))}
                    <Link to="/ContactScreen" class="mob_ld_menu_container">
                        <img className="mob_ld_menu_img" src={require('../Images/LeftDrawer Icon/contact-info-icon-active.svg')} />
                        <div className="mob_ld_menu_name">Contact Information</div>
                      </Link>
        <div class="mob_ld_menu_container" onClick={this.handleSignout}>
          <img
            className="mob_ld_menu_img"
            src={require('../Images/LeftDrawer Icon/logout-icon-active.svg')}
          />
          <div className="mob_ld_menu_name">Sign Out</div>
        </div>

        <div class="mob_version_no">
          <div className="mob_ld_menu_name_version">Version 4.1.0</div>
        </div>
      </div>
    )
  }
}
