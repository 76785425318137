import Paper from '@material-ui/core/Paper'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
// import './MyNeeds.css'
import axios from 'axios'
import CommonLoader from '../CommonLoader'
import { Toast } from 'react-bootstrap'
import ReimbursementUpload from './ReimbursementUpload'

function createData(no, date_of_service, provider, charged, paid, status) {
  return { no, date_of_service, provider, charged, paid, status }
}

const theme = createMuiTheme({
  MuiTableCell: {
    paddingLeft: '30px'
  }
})

const rows = [
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW'),
  createData('600016', '2020-04-20', 'VITALCARE FP LLC', 670, 43, 'FINAL'),
  createData('600017', '2020-05-20', 'VITALCARE FP LLC', 500, 100, 'IN REVIEW')
]

function descendingComparator(a, b, orderBy) {
  if (orderBy == 'contribution_amount') {
    if (parseFloat(b[orderBy].replace('$', '')) < parseFloat(a[orderBy].replace('$', ''))) {
      return -1
    }
    if (parseFloat(b[orderBy].replace('$', '')) > parseFloat(a[orderBy].replace('$', ''))) {
      return 1
    }
    return 0
  } else if (orderBy == 'service_date') {
    if (moment(b[orderBy]).isBefore(a[orderBy])) {
      return -1
    }
    if (moment(b[orderBy]).isAfter(a[orderBy])) {
      return 1
    }
    return 0
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  // { key: 'memberId', id: 'memberId', numeric: false, disablePadding: false, label: 'Member ID' },
  // { key: 'claimId', id: 'claimId', numeric: true, disablePadding: false, label: 'Claim ID' },
  { key: 'fileName', id: 'fileName', numeric: false, disablePadding: false, label: 'Filename' },
  { key: 'uploadedDate', id: 'uploadedDate', numeric: true, disablePadding: false, label: 'Date of Submission' },
  { key: 'status', id: 'status', numeric: true, disablePadding: false, label: ' Status' }
]

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className="tableHeadRow">
        {headCells.map(headCell => (
          <>
            {headCell.key == 'service_date' ? (
              <TableCell
                key={headCell.key}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.key ? order : false}
                className="sortheadercellColor tableHeader myTableHead"
              >
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                key={headCell.key}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.key ? order : false}
                className="headercellColor tableHeader myTableHead"
              >
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  className="headercellColor tableHeader"
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

export default function Reimbursements(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('service_date')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { tableData } = props
  const [reimbursementData, setReimbursementData] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [isReimbursementFlow, setIsReimbursementFlow] = useState(props.isReimbursementFlow);

  useEffect(() => {
    getcontributionData()
  }, [page, rowsPerPage])

  const getcontributionData = () => {
    let member_id = localStorage.getItem('Member_EMPID')
    setLoader(true)
    axios
      .get(
        `${process.env.REACT_APP_NEW_BASE_URL}/memberportal/claims/${member_id.replace(/\D/g, "")}`
      )
      .then(response => {
        // let deencrpt = JSON.parse(atob(response.data))
        if (response) {
          console.log('-- -- -- -- -- ',response);
          
          if (response.data.response.length > 0) {
            setReimbursementData(response.data.response)
            setLoader(false)
          } else {
            setLoader(false)
          }
        } else {
          setLoader(false)
        }
      })
      .catch(err => {
        setLoader(false)
      })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <div className={classes.root}>
      {loader ? <CommonLoader /> : null}
      {
        isReimbursementFlow ?
          <ReimbursementUpload files={props.files} setFiles={(v) => props.setFiles(v)} />
          :
          reimbursementData[0] ?
            <Paper className={'tableContainer ' + classes.paper}>
              <TableContainer style={{ height: 360 }}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />

                  <TableBody className='myTableHead'>
                    {stableSort(reimbursementData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((tableData, index) => {
                        const isItemSelected = isSelected(tableData.name)
                        const labelId = `enhanced-table-checkbox-${index}`

                        return <Row open={props.open} key={tableData.name} row={tableData} />
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                style={{ position: 'relative' }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={reimbursementData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            :
            <div className="data_not_found">
              {!loader &&
                <h5 class="noneeds_msg_display">There is no reimbursements data available at the moment.</h5>
              }
            </div>
      }
    </div>
  )
}

function Row(props) {
  const { row } = props
  const classes = useRowStyles()

  const getDate = date => {
    const isDateValid = moment(date)['_isValid']
    if (isDateValid) {
      return moment(date).format('MMMM D, YYYY')
    }
    return date
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <TableRow className={'rowTable ' + classes.root}>
          {/* <TableCell component="th" scope="row" className="sortrowcellcolor rowTableCell myTableRow">
            {row.memberId ? row.memberId : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow">
            {row.claimId ? row.claimId : 'NA'}
          </TableCell> */}
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow" onClick={() => window.open(row.fileLink)} style={{ cursor: "pointer" }}>
            {row.fileName ? row.fileName : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow">
            {row.uploadedDate ? getDate(row.uploadedDate) : 'NA'}
          </TableCell>
          <TableCell align="left" className="rowcellcolor rowTableCell myTableRow" style={{ width: 175 }}>
            {row.status.includes("Being") ? (
              <span className={'web_status_button Pending'}>{row.status}</span>
            ) :
              row.status == "Rejected" ? (
                <span className={'web_status_button Denied'}>{row.status}</span>
              ) :
                (
                  <span className={'web_status_button Audit'}>{row.status}</span>
                )}
          </TableCell>
        </TableRow>
      </ThemeProvider>
    </React.Fragment>
  )
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      minWidth: 200
    }
  }
})
